@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes quiet {
    25% {
        transform: scaleY(0.6);
    }
    50% {
        transform: scaleY(0.4);
    }
    75% {
        transform: scaleY(0.8);
    }
    }
    
    @keyframes normal {
    25% {
        transform: scaleY(1);
    }
    50% {
        transform: scaleY(0.4);
    }
    75% {
        transform: scaleY(0.6);
    }
    }
    @keyframes loud {
    25% {
        transform: scaleY(1);
    }
    50% {
        transform: scaleY(0.4);
    }
    75% {
        transform: scaleY(1.2);
    }
}
    
.boxContainer {
display: flex;
justify-content: space-between;
height: 64px;
--boxSize: 8px;
--gutter: 4px;
width: calc((var(--boxSize) + var(--gutter)) * 5);
}

.box {
transform: scaleY(0.4);
height: 100%;
width: var(--boxSize);
background: #12e2dc;
animation-duration: 1.2s;
animation-timing-function: ease-in-out;
animation-iteration-count: infinite;
border-radius: 8px;
}

.box1 {
animation-name: quiet;
}

.box2 {
animation-name: normal;
}

.box3 {
animation-name: quiet;
}

.box4 {
    animation-name: loud;
}

.box5 {
    animation-name: quiet;
}
      
span.c { 
    font-weight: bold; 
    color: #2159D6;
}

.transpose-keys {
    margin: 10px 0;
    overflow: auto;
}
.transpose-keys a { 
    display: block;
    float: left;
    width: 2.25em;
    text-align: center;
    margin: 0 .25em .25em 0;
    color: #333;
    background: #eee;
    text-decoration: none;
    padding: .4em 0;
    border: solid 1px transparent;
    outline: none;
}
.transpose-keys a.selected { 
    background: #2159D6; 
    color: #FFF;
}